import React, {useCallback, useEffect, useState, useRef} from 'react';

import { files as fileServices } from '../../services';
import { Button, Modal, Progress } from 'antd';
import { returnToRegister, ToFirstContainerRegistry, } from '../../security';
import { WareHouseAggregation } from '../wareHouse/components';
import './styles.css';
import { FirstListWareHousesSaved } from './components';

export default function Index() {

    const [company, setCompany] = useState(false);
    const [areThereWareHouses, setAreThereWareHouses] = useState(false);
    const [wareHouseSaved, setWareHouseSaved] = useState(null);

    const hasFetchedCompany = useRef(false);
    const hasFetchedWareHouses = useRef(false);
    const hasShownModal = useRef(false);

    useEffect(() => {
        fetchCompany();
    }, []);

    const fetchWareHouses = useCallback(() => {
        if (hasFetchedWareHouses.current) return;

        hasFetchedWareHouses.current = true;
        fileServices.get('warehouse').then((response) => {
            if (response.length > 0) {
                setAreThereWareHouses(true);
                setWareHouseSaved(response);
            } else {
                setAreThereWareHouses(false);
            }
        });
    }, []);

    const fetchCompany = useCallback(() => {
        if (hasFetchedCompany.current) return;

        hasFetchedCompany.current = true;
        fileServices.get('company').then((response) => {
            if (response.length > 0) {
                setCompany(true);
                fetchWareHouses();
                if (!hasShownModal.current) {
                    showInfoModal();
                    hasShownModal.current = true;
                }
            }
        });
    }, [fetchWareHouses]);


    const showInfoModal = () => {
        Modal.info({
            title: 'Atención',
            content: (
                <div>
                    <br />
                    <br />
                    <p style={{ fontSize: '20px' }}>
                        La información agregada al registrar los almacenes no puede ser editada, por lo cual es de suma
                        importancia ingresar y verificar la información cuidadosamente ya que este registro queda a
                        responsabilidad del usuario.
                    </p>
                    <br />
                </div>
            ),
            width: 1000,
            onOk() {},
        });
    };


    const handleAddWareHouseClick = () => {
        setTimeout(() => {
            ToFirstContainerRegistry();
        }, 0);
    };

    return (
        <div>
            {company ? (
                <div>
                    {wareHouseSaved ? (
                        <div>
                            <FirstListWareHousesSaved wareHouseSaved={wareHouseSaved} />
                        </div>
                    ) : (
                        <div>
                            <span style={{ fontSize: '24px' }}>Sin almacenes registrados</span>
                        </div>
                    )}
                    <div>
                        <br />
                        <br />
                        <WareHouseAggregation
                            onAddWareHouseClick={handleAddWareHouseClick}
                            hideCancelButton={true}
                        />
                    </div>
                </div>
            ) : (
                <div className="center-div">
                    <Progress type="circle" percent={70} status="exception" />
                    <div style={{ margin: '8px' }}>
                        <span>
                            Por favor registra los datos de la empresa antes de añadir contenedores
                        </span>
                    </div>
                    <Button className="back-button" key="back" onClick={returnToRegister}>
                        Registrar empresa
                    </Button>
                </div>
            )}
        </div>
    );
}
