import React, { Fragment } from 'react';
import { Form } from '@ant-design/compatible';

import { Button, Modal, message, Row, Col, InputNumber, DatePicker, Select } from 'antd';
import { DISTRIBUTION_POINT_PERMISSIONS, getPagePermissions } from '../../../security/permissions';
import { connect } from 'react-redux';
import request from '../../../services/request';
import moment from 'moment';
import { serializeParams } from '../../../common/helpers';
const { Option } = Select;

const CreateTraspasos = Form.create({ name: 'create_traspasos_modal' })(
    class extends React.Component {

        constructor() {
            super();
            this.state = {
                regEntrada: [],
                warehouses: [],
            };
        }

        successResponse = () => {
            let traspasoMethod = (this.props.traspaso !== undefined) ? "editado" : "registrado";
            message.success("El traspaso fue ".concat(traspasoMethod).concat(" correctamente."));
            this.props.form.resetFields();
            this.props.closeModal();
            this.props.refreshView();
        }

        getRegEntrada = () => {
            const params = {
                applicationType: 'REGENTRADA'
            };
            request().get(`distributionPoints/${this.props.distributionPointId}/inputEquipment${serializeParams(params)}`)
                .then(response => {
                    this.setState({ regEntrada: response.data });
                    this.getWarehouses();
                });
        }

        getWarehouses = () => {
            request().get(`distributionPoints/${this.props.distributionPointId}/warehouses`)
                .then(response => {
                    this.setState({ warehouses: response.data });
                });
        }

        handleSubmit = () => {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    values.idEquipment = (values.idEquipment === 'none') ? undefined : values.idEquipment;
                    values.initDate = values.initDate.format('YYYY-MM-DD HH:mm:ss');
                    values.endDate = values.endDate.format('YYYY-MM-DD HH:mm:ss');
                    if (this.props.traspaso !== undefined) {
                        values.traspasoId = this.props.traspaso.id;
                        request().put(`traspasos`, values).then((response) => {
                            this.successResponse();
                        }).catch((error) => {
                            message.error(error.response.data.message);
                        });
                    } else {
                        request().post(`traspasos`, values).then((response) => {
                            this.successResponse();
                        }).catch((error) => {
                            message.error(error.response.data.message);
                        });
                    }
                }
            })
        }

        getSelectOptionFromMap = (filterType) => {
            if (filterType === 'RegEntrada') {
                let regEntradaMap = this.state.regEntrada.map(item => (
                    <Option key={item.idEquipment} value={item.idEquipment}> REGENTRADA - {item.equipmentKey}</Option>
                ));
                regEntradaMap.unshift(<Option key="none" value="none"> Ninguno </Option>);
                return regEntradaMap;
            }
            if (filterType === 'Warehouse') {
                return this.state.warehouses.map(item => (
                    <Option key={item.idWareHouse} value={item.idWareHouse}> {item.warehouseKey} - {item.description}</Option>
                ));
            }
            return;
        }

        resetAndCloseModal = () => {
            this.props.form.resetFields();
            this.props.closeModal();
        }


        render() {
            const { getFieldDecorator } = this.props.form;
            const allowEdit = this.props.traspaso !== undefined;
            const { hidden } = this.props
            if (!hidden && this.state.regEntrada.length === 0) {
                this.getRegEntrada();
            }
            return (
                <Fragment >
                    <div style={{ display: 'flex' }}>
                        <Modal
                            open={!this.props.hidden}
                            width="80%"
                            title={(this.props.traspaso === undefined) ? "Registro de Traspaso Manual" : "Edición de Traspaso"}
                            onCancel={this.resetAndCloseModal}
                            footer={[
                                <Button onClick={() => {
                                    this.resetAndCloseModal();
                                }} key='return'>Volver</Button>,
                                <Button type="primary" onClick={() => this.handleSubmit()} key='submit'>{(this.props.traspaso === undefined) ? "Crear Registro" : "Modificar Traspaso"} </Button>
                            ]}
                        >
                            <div>
                                <Form layout='vertical' >
                                    <Row gutter={24} style={{marginBottom: 20}}>
                                        <Col span={8}>
                                            <Form.Item label="Folio" className='text-wrap'>
                                                {getFieldDecorator(`folio`, {
                                                    initialValue: (allowEdit) ? this.props.traspaso.inputRegisterFolio : undefined,
                                                    rules: [
                                                        { required: true, message: 'Requerido para expresar el número de registro único y consecutivo de cada recepción, generado por el programa informático.' },
                                                    ]
                                                })(
                                                    <InputNumber style={{ width: '100%' }} min={1} max={2000} precision={0} disabled={allowEdit} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label="Volumen del Tanque antes de iniciar" className='text-wrap'>
                                                {getFieldDecorator(`initialVolumeTank`, {
                                                    initialValue: (allowEdit) ? this.props.traspaso.initialVolumeTank : undefined,
                                                    rules: [
                                                        { required: true, message: 'Requerido para expresar el volumen inicial antes de la recepción del producto por operación' },
                                                    ]
                                                })(
                                                    <InputNumber style={{ width: '100%' }} min={-100000000000.0} max={100000000000.0} precision={3} disabled={allowEdit} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label="Volumen del Tanque despues de finalizar" className='text-wrap'>
                                                {getFieldDecorator(`finalVolumeTank`, {
                                                    initialValue: (allowEdit) ? this.props.traspaso.finalVolumeTank : undefined,
                                                    rules: [
                                                        { required: true, message: 'Requerido para expresar el volumen final después de la recepción del producto por operación' },
                                                    ]
                                                })(
                                                    <InputNumber style={{ width: '100%' }} min={-100000000000.0} max={100000000000.0} precision={3} disabled={allowEdit} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24} style={{marginBottom: 20}}>
                                        <Col span={8}>
                                            <Form.Item label="Volumen" className='text-wrap'>
                                                {getFieldDecorator(`receptionVolume`, {
                                                    initialValue: (allowEdit) ? this.props.traspaso.inputRegisterVolume : undefined,
                                                    rules: [
                                                        { required: true, message: 'Requerido para expresar el volumen final después de la recepción del producto por operación' },
                                                    ]
                                                })(
                                                    <InputNumber style={{ width: '100%' }} min={-100000000000.0} max={100000000000.0} precision={3} disabled={allowEdit} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label="Temperatura" className='text-wrap'>
                                                {getFieldDecorator(`temperature`, {
                                                    initialValue: (allowEdit) ? this.props.traspaso.temperature : undefined,
                                                    rules: [
                                                        { required: true, message: 'Requerido para expresar la temperatura del tanque a la que se realizó la cuantificación y/o totalización de la masa o volumen del hidrocarburo o petrolífero de que se trate, tomando en consideración las condiciones de referencia. La unidad de medida son grados Celsius.' },
                                                    ]
                                                })(
                                                    <InputNumber style={{ width: '100%' }} min={-170} max={100} precision={3} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label="Presión" className='text-wrap'>
                                                {getFieldDecorator(`absolutePressure`, {
                                                    initialValue: (allowEdit) ? this.props.traspaso.absolutePressure : undefined,
                                                    rules: [
                                                        { required: true, message: 'Requerido para expresar la presión absoluta a la que se realizó la cuantificación y/o totalización de la masa o volumen del hidrocarburo o petrolífero de que se trate, tomando en consideración las condiciones de referencia. La unidad de medida es kPa.' },
                                                    ]
                                                })(
                                                    <InputNumber style={{ width: '100%' }} min={0} max={200} precision={3} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24} style={{marginBottom: 20}}>
                                        <Col span={8}>
                                            <Form.Item label="Fecha y hora inicial" className='text-wrap'>
                                                {getFieldDecorator('initDate', {
                                                    initialValue: (allowEdit) ? moment(this.props.traspaso.stringInitDate) : undefined,
                                                    rules: [{
                                                        required: true, message: 'Requerido para expresar la fecha y hora inicial de cada operación de recepción, deberá expresarse la hora en UTC con la indicación del huso horario yyyy-mm-ddThh:mm:ss+-hh:mm, de acuerdo con la especificación ISO 8601.'
                                                    }],
                                                })(
                                                    <DatePicker
                                                        disabled={allowEdit}
                                                        showTime={true}
                                                        showToday={true}
                                                        placeholder={"Fecha y hora inicial del traspaso"}
                                                        style={{ width: '100%' }}
                                                        format={'DD-MM-YYYY HH:mm:ss'}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label="Fecha y hora final" className='text-wrap'>
                                                {getFieldDecorator('endDate', {
                                                    initialValue: (allowEdit) ? moment(this.props.traspaso.stringEndlDate) : undefined,
                                                    rules: [{
                                                        required: true, message: 'Requerido para expresar la fecha y hora final de cada operación de recepción, deberá expresarse la hora en UTC con la indicación del huso horario yyyy-mm-ddThh:mm:ss+-hh:mm, de acuerdo con la especificación ISO 8601.'
                                                    }],
                                                })(
                                                    <DatePicker
                                                        disabled={allowEdit}
                                                        showTime={true}
                                                        showToday={true}
                                                        placeholder={"Fecha y hora final del traspaso"}
                                                        style={{ width: '100%' }}
                                                        format={'DD-MM-YYYY HH:mm:ss'}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label="Número de almacén" className='text-wrap'>
                                                {getFieldDecorator('warehouseNumber', {
                                                    initialValue: (allowEdit) ? this.props.traspaso.warehouse.warehouseNumber : undefined,
                                                    rules: [{ required: true, message: 'Número de almacén' }]
                                                })(
                                                    <InputNumber disabled style={{ width: '100%' }} min={1} max={100} precision={0} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24} style={{marginBottom: 20}}>
                                        <Col span={8}>
                                            <Form.Item label="Almacén" className='text-wrap'>
                                                {getFieldDecorator(`idWarehouse`, {
                                                    initialValue: (allowEdit) ? this.props.traspaso.warehouse.idWareHouse : undefined,
                                                    rules: [
                                                        { required: true, message: 'Seleccione el almacén al que pertecence el traspaso' },
                                                    ]
                                                })(
                                                    <Select onChange={(idWarehouse) => {
                                                        let wareHouse = this.state.warehouses.find(w => w.idWareHouse === idWarehouse);
                                                        let warehouse_number = wareHouse !== undefined ? wareHouse.warehouseNumber : undefined;
                                                        this.props.form.setFieldsValue({ warehouseNumber: warehouse_number });
                                                    }} disabled={allowEdit}>
                                                        {this.getSelectOptionFromMap('Warehouse')}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} hidden>
                                            <Form.Item label="Registro de Entrada" className='text-wrap'>
                                                {getFieldDecorator(`idEquipment`, {
                                                    initialValue: (allowEdit) ? this.props.traspaso.idWarehouse : 'none',
                                                })(
                                                    <Select>
                                                        {this.getSelectOptionFromMap('RegEntrada')}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Modal>
                    </div>
                </Fragment>
            );
        }
    }
);
const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISTRIBUTION_POINT_PERMISSIONS, permissions)
});

export default connect(mapStateToProps)(CreateTraspasos);
