import React, {useCallback, useEffect, useRef, useState} from 'react';

import { files as fileServices } from '../../services';
import { Button, Modal, Progress } from 'antd';
import { ContainerAggregation } from '../containers/components';
import { FirstListContainersSaved } from './components';
import { ToFirstWarehouseRegistry } from '../../security';

export default function Index() {
    const [warehouse, setWarehouse] = useState(false);
    const [areThereContainers, setAreThereContainers] = useState(false);
    const [containersSaved, setContainersSaved] = useState(null);

    const hasFetchedWarehouses = useRef(false);
    const hasShownModal = useRef(false);

    useEffect(() => {
        if (!hasFetchedWarehouses.current) {
            hasFetchedWarehouses.current = true;
            fetchWarehouses();
        }
    }, []);

    const fetchWarehouses = useCallback(() => {
        fileServices.get('warehouse').then((response) => {
            if (response.length > 0) {
                setWarehouse(true);
                fetchContainers();
                if (!hasShownModal.current) {
                    hasShownModal.current = true;
                    showInfoModal();
                }
            }
        });
    }, []);

    const fetchContainers = useCallback(() => {
        fileServices.get('container').then((response) => {
            if (response.length > 0) {
                setAreThereContainers(true);
                setContainersSaved(response);
            } else {
                setAreThereContainers(false);
            }
        });
    }, []);

    const showInfoModal = () => {
        Modal.info({
            title: 'Atención',
            content: (
                <div>
                    <br />
                    <br />
                    <p style={{ fontSize: '20px' }}>
                        La información agregada al registrar contenedores no puede ser editada, por lo cual es de suma
                        importancia ingresar y verificar la información cuidadosamente ya que este registro queda a
                        responsabilidad del usuario.
                    </p>
                    <br />
                </div>
            ),
            width: 1000,
            onOk() {},
        });
    };

    return (
        <div>
            {warehouse ? (
                <div>
                    {containersSaved ? (
                        <div>
                            <FirstListContainersSaved containersSaved={containersSaved} />
                        </div>
                    ) : (
                        <div>
                            <span style={{ fontSize: '24px' }}>Sin contenedores agregados</span>
                        </div>
                    )}
                    <div>
                        <br />
                        <br />
                        <ContainerAggregation hideCancel={true} isInitialConfiguration={true} />
                    </div>
                </div>
            ) : (
                <div className="center-div">
                    <Progress type="circle" percent={70} status="exception" />
                    <div style={{ margin: '8px' }}>
                        <span>Por favor registra algún almacén antes de añadir contenedores</span>
                    </div>
                    <Button className="back-button" key="back" onClick={ToFirstWarehouseRegistry}>
                        Registrar almacén
                    </Button>
                </div>
            )}
        </div>
    );
}