import {createContext, useEffect, useState} from 'react';

const DistributionPointSelected = createContext();

export const MyDistributionPoint = ({ children }) => {
    const [distributionPoint, setDistributionPoint] = useState(() => {
        const storedValue = localStorage.getItem('distributionPoint');
        return storedValue ? JSON.parse(storedValue) : {};
    });

    useEffect(() => {
        localStorage.setItem('distributionPoint', JSON.stringify(distributionPoint));
    }, [distributionPoint]);

    return (
        <DistributionPointSelected.Provider value={{distributionPoint, setDistributionPoint}}>
            {children}
        </DistributionPointSelected.Provider>
    )
}

export default DistributionPointSelected;